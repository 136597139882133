




























































































































































































































































































































































import CreateNewProgram from "@/components/inviteProgramAdmin/InviteProgram";
import "@/components/inviteProgramAdmin/inviteProgram.less";
export default CreateNewProgram;

