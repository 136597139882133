import { Vue, Component } from 'vue-property-decorator'
import { ScreenText } from '@/lang/ScreenText'
import COMMON_CONST from '@/constants/CommonConst'
import { account_details } from "@/Model/model";
import APP_CONST from '@/constants/AppConst'
import APP_UTILITIES from "@/utilities/commonFunctions";
import { AccountDetails, CreateProgramObj } from '@/Model/model.js';
import UIkit from "uikit";
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import programConfigureStore from '@/store/modules/programConfigure'



@Component({
    components: {
        'datepicker': DatepickerComponent
    }
})
export default class InviteProgram extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    private isEdited: boolean = false;
    private isDataCanAutoSave: boolean = false;
    private saveTimer: number = 0;
    private itemToIgnore = ['programAdminId',"isFinalSubmit", "description", "roleId", "accId"];
    private notRequired = ['phoneNumber', "id", "personalInvitationNote", "description"];
    private numberOnlyField: string[] = ['numberOfScholars'];
    private stringOnlyField: string[] = ['programAdmin', 'firstName', 'lastName'];
    private emailOnlyField: string[] = ['email'];
    private autoSaveData: any = {};
    private isEditMode: boolean = false;
    private isDataCanSaveGlobal: boolean = true;
    private isPhoneCanSave: boolean = true;
    private isSaveandContinueLater: boolean = false;
    public accountDt: AccountDetails = {} as AccountDetails;
    public dateIsGreater: boolean = false;
    public duplicateEmail: boolean = false;
    public duplicateEmailMsg: any = '';
    public checkForCorrectEmail: boolean = true;
    public buttonState: boolean = false;
    public numberOfScholarError: boolean = false;
    private ignoreItems = ['id', 'status'];
    private globalNameCheck: any = {
        'firstName': {
            value: '',
            required: false,
            minimunlength: false
        },
        'lastName': {
            value: '',
            required: false,
            minimunlength: false
        }
    }
    private programId: number = 0




    private account_data: any = {
        "programAdminId": 0,
        "isFinalSubmit": true,
        "programAdmin": {
            "firstName": {
                "value": "",
                "required": true,
                "minimunlength": false
            },
            "lastName": {
                "value": "",
                "required": true,
                "minimunlength": false
            },
            "email": {
                "value": "",
                "required": true
            },
            "phoneNumber": {
                "value": "",
                "required": false
            },
            "personalInvitationNote": {
                "value": "",
                "required": false
            }
        },
        "programs": [
            {
                "id": {
                    "value": 0,
                    "required": false
                },
                "name": {
                    "value": "",
                    "required": true
                },
                "description": {
                    "value": "",
                    "required": false
                },
                "startDate": {
                    "value": "",
                    "required": true
                },
                "endDate": {
                    "value": "",
                    "required": true
                },
                "numberOfScholars": {
                    "value": "",
                    "required": true
                }
            }
        ]
    };



    private checkForCorrectDate(type: string, index: number) {
        let startDate = <HTMLInputElement>document.getElementById('ele_program_start_date');
        let endDate = <HTMLInputElement>document.getElementById('ele_program_end_date');
        if (type === 'startDate') {
            let strstartDate = new Date((<any>this.accountData).programs[index].startDate.value).setHours(0, 0, 0, 0);
            let strendDate = new Date((<any>this.accountData).programs[index].endDate.value).setHours(0, 0, 0, 0);
            if ((<any>this.accountData).programs[index].endDate.value != '') {
                /* istanbul ignore else */
                if (strendDate && strstartDate) {
                    if (strstartDate >= strendDate) {
                        (<any>this.accountData).programs[index].endDate.required = false;
                        setTimeout(() => {
                            this.dateIsGreater = true;
                            (<any>this.accountData).programs[index].endDate.required = false;
                            (<any>this.accountData).programs[index].endDate.value = '';
                            endDate.value = "";
                        })
                    } else {
                        this.dateIsGreater = false;
                        (<any>this.accountData).programs[index].endDate.required = true;
                        (<any>this.accountData).programs[index].startDate.required = true;
                    }
                }
            }
        } else
            if (type === APP_CONST.END_DATE) {
                if (startDate.value.length === APP_CONST.ZERO) {
                    (<any>this.accountData).programs[index].startDate.required = false;
                    setTimeout(() => { endDate.value = ""; startDate.value = ''; });
                } else {
                    (<any>this.accountData).programs[index].startDate.required = true;
                }
                let endStr: any = new Date((<any>this.accountData).programs[index].endDate.value).setHours(0, 0, 0, 0);
                let startStr: any = new Date((<any>this.accountData).programs[index].startDate.value).setHours(0, 0, 0, 0)
                if (endStr <= startStr) {
                    (<any>this.accountData).programs[index].endDate.required = false;
                    setTimeout(() => {
                        this.dateIsGreater = true;
                        (<any>this.accountData).programs[index].endDate.required = false;
                        (<any>this.accountData).programs[index].endDate.value = '';
                        endDate.value = "";
                    })
                } else {
                    this.dateIsGreater = false;
                    (<any>this.accountData).programs[index].endDate.required = true;
                    (<any>this.accountData).programs[index].startDate.required = true;
                }
            }
        this.isDataCanAutoSave = true;

    }

    get accountData() {
        return this.account_data;
    }

    set accountData(objData: account_details) {
        this.account_data = objData;
    }

    get getID() {
        return COMMON_CONST;
    }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    public checkAlphabet(event: any) {
        if (event.keyCode == 69) {
            return
        }
    }



    private validateValue(strFieldName: string, data: any): boolean {
        let isDataValid: boolean = false;
        let keyName: string = '';
        let isDefaultRequired = data.required;
        if (isDefaultRequired) {
            keyName = (this.numberOnlyField.indexOf(strFieldName) > -1) ? APP_CONST.NUMBER : keyName;
            keyName = (this.stringOnlyField.indexOf(strFieldName) > -1) ? APP_CONST.STRING : keyName;
            keyName = (this.emailOnlyField.indexOf(strFieldName) > -1) ? APP_CONST.EMAIL : keyName;

            let isValid: boolean = true;
            switch (keyName) {
                case APP_CONST.STRING:
                    isValid = !(/\d/.test(data.value));
                    /* istanbul ignore else */
                    if (data.required === true) {
                        data.required = isValid;
                    }
                    break;
                case APP_CONST.EMAIL:
                    data.required = this.emailValidator(data.value);
                    break;
                case APP_CONST.NUMBER:

                    if (strFieldName === APP_CONST.PHONE_NUMBER) {
                        let cleaned: any = ('' + data.value).replace(/\D/g, '')
                                              isValid = (String(cleaned).length === 10)

                    } else if (strFieldName === APP_CONST.NO_OF_SCHOLARS) {
                        isValid = (data.value > 0)
                    } else {
                        isValid = !isNaN(data.value);
                    }
                    /* istanbul ignore else */
                    if (data.required === true) {
                        data.required = isValid;
                    }
                    break;

            }


            isDataValid = data.required;
        }

        if (isDefaultRequired === false) {
            isDataValid = true;
        }
        return isDataValid;

    }


    public resetToOldStatus() {
        for (let strKey in this.accountData) {
            let itemNode: any = (<any>this.accountData)[strKey]

            if (this.itemToIgnore.indexOf(strKey) < 0 && strKey != APP_CONST.PROGRAMS && strKey != APP_CONST.PROGRAM_ADMIN) {
                itemNode.required = (this.notRequired.indexOf(strKey) == -1);
            } else if (strKey === APP_CONST.PROGRAMS) {
                for (let k = 0; k < itemNode.length; k++) {
                    for (let childKey in itemNode[k]) {
                        let childNode: any = itemNode[k][childKey]
                        childNode.required = (this.notRequired.indexOf(childKey) == -1);
                    }
                }
            } else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                for (let childKey in itemNode) {
                    let childNode: any = itemNode[childKey]
                    childNode.required = (this.notRequired.indexOf(childKey) == -1);
                }
            }
        }
    }
    public validateForm() {
        this.resetToOldStatus();
        let dataToSend: any = {}
        dataToSend.programAdminId = APP_UTILITIES.getCookie(APP_CONST.USER_ID);
        dataToSend.isFinalSubmit = true;
        this.isEdited = false;
        let isDataCanSave: boolean = true;
        this.isDataCanAutoSave = false;
        let isFilledDataValid: boolean = true;
        for (let strKey in this.accountData) {

            let itemNode: any = (<any>this.accountData)[strKey]
            if (strKey != APP_CONST.PROGRAMS && strKey != APP_CONST.PROGRAM_ADMIN) {
                if (this.itemToIgnore.indexOf(strKey) < 0) {
                    if (itemNode.required === true) {
                        itemNode.required = (itemNode.value != "");
                        if (isDataCanSave === true) {
                            isDataCanSave = (itemNode.value != "")
                        }
                    }

                    let bValid = this.validateValue(strKey, itemNode);
                    if (isFilledDataValid === true) {
                        isFilledDataValid = bValid;
                    }
                    dataToSend[strKey] = itemNode.value;
                }

            } else if (strKey === APP_CONST.PROGRAMS) {
                dataToSend[strKey] = [];
                for (let k = 0; k < itemNode.length; k++) {
                    let childData: any = {}
                    for (let childKey in itemNode[k]) {
                        let childNode: any = itemNode[k][childKey]
                        if (childNode.required === true) {
                            childNode.required = (childNode.value != "");
                            /* istanbul ignore else */
                            if (isDataCanSave === true) {
                                isDataCanSave = (childNode.value != "")
                            }
                        }

                        childData[childKey] = childNode.value;
                        let bValid = this.validateValue(childKey, childNode);
                        /* istanbul ignore else */
                        if (isFilledDataValid === true) {
                            isFilledDataValid = bValid;
                        }


                    }
                    dataToSend[strKey].push(childData)
                }
            }
            /* istanbul ignore else */
            else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                dataToSend[strKey] = {}
                let childObject: any = {};
                for (let childKey in itemNode) {
                    let childNode: any = itemNode[childKey]
                    if (childNode.required === true) {
                        childNode.required = (childNode.value != "");
                        /* istanbul ignore else */
                        if (isDataCanSave === true) {
                            isDataCanSave = (childNode.value != "")
                        }
                    }
                    if (childKey === APP_CONST.PHONE_NUMBER) {
                        let cleaned: any = (childNode.value && childNode.value.charAt(0) == '0') ? '' : ('' + childNode.value).replace(/\D/g, '');

                        if (cleaned != '' && cleaned.length > 10) {
                            childObject[childKey] = cleaned.substring(0, 10);
                        } else {
                            childObject[childKey] = cleaned
                        }
                    } else {
                        childObject[childKey] = childNode.value;
                    }

                    let bValid = this.validateValue(childKey, childNode);
                    /* istanbul ignore else */
                    if (isFilledDataValid === true) {
                        isFilledDataValid = bValid;
                    }

                }
                dataToSend[strKey] = childObject;
            }
        }

        this.isDataCanSaveGlobal = isDataCanSave;
        if(dataToSend.programs[0].numberOfScholars == 0){
            this.numberOfScholarError = true;
            isDataCanSave = false;
        }
        let validateFnameAndLnameResponse = this.validateFnameAndLname(dataToSend)
        /* istanbul ignore else */
        if (validateFnameAndLnameResponse) {
            this.isPhoneCanSave = true;
            if (dataToSend.programAdmin && dataToSend.programAdmin.phoneNumber && dataToSend.programAdmin.phoneNumber.length < 10) {
                this.isPhoneCanSave = false;
            } else {
                this.isPhoneCanSave = true;
            }
            this.checkForCorrectEmail = true;
            if ((dataToSend.programAdmin) && (dataToSend.programAdmin.email) && (!this.emailValidator(dataToSend.programAdmin.email))) {
                this.checkForCorrectEmail = false;
            } else {
                this.checkForCorrectEmail = true
            }
            /* istanbul ignore else */
             if (isDataCanSave === true && isFilledDataValid && this.isPhoneCanSave && this.checkForCorrectEmail) {
                this.saveNewAccountData(dataToSend);
            }
        }
    }

    public validateFnameAndLname(dataToSend: any) {
        /* istanbul ignore else */
        if (dataToSend.programAdmin) {
            /* istanbul ignore else */
            if (dataToSend.programAdmin.firstName.length > 0) {
                this.globalNameCheck.firstName.required = false
                this.globalNameCheck.firstName.minimunlength = false
                let firstName = APP_UTILITIES.validateAlphaOnly(dataToSend.programAdmin.firstName);
                let firstNameMinimumLength = APP_UTILITIES.checkMinimumLength(dataToSend.programAdmin.firstName);
                if (!firstName || firstNameMinimumLength) {
                    this.globalNameCheck.firstName.required = !firstName
                    this.globalNameCheck.firstName.minimunlength = firstNameMinimumLength
                }
            }
            /* istanbul ignore else */
            if (dataToSend.programAdmin.lastName.length > 0) {
                this.globalNameCheck.lastName.required = false
                this.globalNameCheck.lastName.minimunlength = false
                let lastName = APP_UTILITIES.validateAlphaOnly(dataToSend.programAdmin.lastName);
                let lastNameMinimumLength = APP_UTILITIES.checkMinimumLength(dataToSend.programAdmin.lastName);
                if (!lastName || lastNameMinimumLength) {
                    this.globalNameCheck.lastName.required = !lastName
                    this.globalNameCheck.lastName.minimunlength = lastNameMinimumLength
                }
            }
        }
        let breakLoop = false
        for (let keys in this.globalNameCheck) {
            /* istanbul ignore else */
            if (this.globalNameCheck[keys]) {
                if (this.globalNameCheck[keys].required === true || this.globalNameCheck[keys].minimunlength === true) {
                    breakLoop = true
                    break
                }
            }
        }
        if (breakLoop) {
            return false
        }
        return true
    }

    public prepareDataForAutoSave(): any {
        let dataToSend: any = {}
        dataToSend.programAdminId = (<any>this.accountData).programAdminId;
        dataToSend.isFinalSubmit = false;
        this.isDataCanAutoSave = false;
        for (let strKey in this.accountData) {
            let itemNode: any = (<any>this.accountData)[strKey]
            if (strKey != APP_CONST.PROGRAMS && strKey != APP_CONST.PROGRAM_ADMIN && strKey != APP_CONST.LICENSE) {
                if (this.itemToIgnore.indexOf(strKey) < 0) {
                    dataToSend[strKey] = itemNode.value;
                }
            }
            else if (strKey === APP_CONST.PROGRAMS) {
                dataToSend[strKey] = [];
                for (let k = 0; k < itemNode.length; k++) {
                    let childData: any = {}
                    for (let childKey in itemNode[k]) {
                        let childNode: any = itemNode[k][childKey]
                        childData[childKey] = childNode.value;
                    }
                    dataToSend[strKey].push(childData)
                }
            } else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                dataToSend[strKey] = {}
                let childObject: any = {};
                for (let childKey in itemNode) {
                    let childNode: any = itemNode[childKey]
                    if (childKey === APP_CONST.PHONE_NUMBER) {
                        let cleaned: any = ('' + itemNode[childKey].value).replace(/\D/g, '')
                        if (cleaned != '' && cleaned.length > 10) {
                            childObject[childKey] = cleaned.substring(0, 10);
                        } else {
                            childObject[childKey] = cleaned
                        }
                    } else {
                        childObject[childKey] = childNode.value;
                    }

                }
                dataToSend[strKey] = childObject;
            }
        }
        this.autoSaveData = dataToSend;
        this.autoSaveAccountData(dataToSend)
        return this.autoSaveData;
    }

    goToHome() {
        this.$router.push('/home');
    }

    private saveNewAccountData(dataToSend: any) {
        let payloadData: any = this.prepareDateForAPIPayload(dataToSend);
        programConfigureStore.sendInviteProgram(payloadData).then((response:any) => {
            if (response.status === APP_CONST.RESPONSE_200) {
                UIkit.modal('#invite-manager-sent').show();
            } else if (response.status === APP_CONST.RESPONSE_400) {
                if (response.data.name === "Error_UserAlreadyRegistered") {
                    this.duplicateEmail = true;
                    this.duplicateEmailMsg = response.data.value
                }
            }
        })
    }


  
    private updateAccountData(dataToSend: any) {
        let payloadData: any = this.prepareDateForAPIPayload(dataToSend);
        payloadData.validationMode = 2;
        programConfigureStore.updatedProgramWithEditedData(payloadData).then(response => {
            this.updatedProgramWithEditedDataRes(response);
        })
    }

    public updatedProgramWithEditedDataRes(response: any){
        if (response.data.status === APP_CONST.RESPONSE_200) {
            UIkit.modal('#invite-manager-sent').show();
        } else if (response.status === APP_CONST.RESPONSE_400) {
            if (response.data.name === "Error_UserAlreadyRegistered") {
                this.duplicateEmail = true;
                this.duplicateEmailMsg = response.data.value
            }
        }
    }

    public saveAndContinueLater() {
        this.isSaveandContinueLater = true;
        this.prepareDataForAutoSave();
    }

    prepareDateForAPIPayload(dataToSend: any) {
        let getAccountIdFromCookie: any = APP_UTILITIES.getCookie('accountId')
        let accountId = JSON.parse(getAccountIdFromCookie);
        return {
            "programAdminId": 0,
            "isFinalSubmit": dataToSend.isFinalSubmit,
            "validationMode": 0,
            "isProgramDateChanged": true,
            "programDto": {
                "id": (!this.isEditMode) ?0 : dataToSend.programs[0].id,
                "roleId": 0,
                "accId": accountId || 0,
                "name": dataToSend.programs[0].name || '',
                "description": dataToSend.programs[0].description || '',
                "startDate": dataToSend.programs[0].startDate || '',
                "endDate": dataToSend.programs[0].endDate || '',
                "numberOfScholars": dataToSend.programs[0].numberOfScholars || 0,
                "status": +1,
            },
            "programAdmin": {
                "firstName": dataToSend.programAdmin.firstName || '',
                "lastName": dataToSend.programAdmin.lastName || '',
                "email": dataToSend.programAdmin.email || '',
                "phoneNumber": dataToSend.programAdmin.phoneNumber || '',
                "personalInvitationNote": dataToSend.programAdmin.personalInvitationNote || ''
            }
        }
    }

    private autoSaveAccountData(dataToSend: any) {
        let payloadData: any = this.prepareDateForAPIPayload(dataToSend);
        programConfigureStore.sendInviteProgram(payloadData).then((response: any) => {
            if (this.isSaveandContinueLater) {
                this.isSaveandContinueLater = false
                this.goToHome()
            }

        })
    }

    public clearForm() {
        this.buttonState = false;
        this.isEdited = false;
        this.isDataCanSaveGlobal = true;
        this.duplicateEmail = false;
        this.numberOfScholarError = false;
        this.checkForCorrectEmail = true;
        for (let strKey in this.accountData) {
            let itemNode: any = (<any>this.accountData)[strKey]

            if (this.itemToIgnore.indexOf(strKey) < 0 && strKey != APP_CONST.PROGRAMS && strKey != APP_CONST.PROGRAM_ADMIN) {
                itemNode.value = "";
                itemNode.required = (this.notRequired.indexOf(strKey) == -1);
            }  else if (strKey === APP_CONST.PROGRAMS) {
                for (let k = 0; k < itemNode.length; k++) {
                    for (let childKey in itemNode[k]) {
                        if(childKey!="numberOfScholars" && childKey!="id"){
                        let childNode: any = itemNode[k][childKey]
                        childNode.value = '';
                        childNode.required = (this.notRequired.indexOf(childKey) == -1);
                        }
                    }
                }
            } 
            else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                for (let childKey in itemNode) {
                    let childNode: any = itemNode[childKey]
                    childNode.value = '';
                    childNode.required = (this.notRequired.indexOf(childKey) == -1);
                }
            }
        }
    }


    public getAccountId() {
        let getAccountIdFromCookie: any = APP_UTILITIES.getCookie('accountId')
        let accountId = JSON.parse(getAccountIdFromCookie);
        return accountId;
    }
    public beforeMount(): any {
        if (this.$route.name === COMMON_CONST.INVITE_PROGRAM_ADMIN) {
            this.loadSavedData();
        }
    }

    public fetchAccountDetails(programId: string): any {
        programConfigureStore.getInviteProgramConfigObj(parseInt(programId)).then((response:any) => {
            let responseData: any = (response.data === undefined) ? null : response.data
            return this.fillDataForEdit(this.dataToRender(responseData))
        })
    }


    public loadSavedData(): any {
        let programId = APP_UTILITIES.getCookie("editProgramId");
        let dataToSend = {
            accountId: this.getAccountId(),
            programId: programId,
            entityTypes:4
        }
        programConfigureStore.getAutoSaveProgramConfig(dataToSend).then((newData: any) => { 
             let responseData: any = newData.data === undefined ? null : newData.data
            /* istanbul ignore else */
            if(responseData && responseData.programDto && responseData.programDto.id > 0){
                this.isEditMode = true;
                return this.fillFormWithAPIData(this.programDataToRender(responseData)) 
            }else{
                this.isEditMode = true;
               
                this.fetchAccountDetails(String(programId));
            }
           
        })
        return this.accountData;


    }

    public programDataToRender(apiData: CreateProgramObj) {
        return {
            "programAdminId": apiData.programAdminId || 0,
            "isFinalSubmit": apiData.isFinalSubmit,
            "programAdmin": {
                "firstName": apiData.programAdmin && apiData.programAdmin.firstName || '',
                "lastName": apiData.programAdmin && apiData.programAdmin.lastName || '',
                "email": apiData.programAdmin && apiData.programAdmin.email || '',
                "phoneNumber": apiData.programAdmin && apiData.programAdmin.phoneNumber || '',
                "personalInvitationNote": apiData.programAdmin && apiData.programAdmin.personalInvitationNote || ''
            },
            "programs": [
                {
                    "id": apiData.programDto.id,
                    "name": apiData.programDto.name || '',
                    "description": apiData.programDto.description || '',
                    "startDate": apiData.programDto.startDate || '',
                    "endDate": apiData.programDto.endDate || '',
                    "numberOfScholars": apiData.programDto.numberOfScholars || ''
                }
            ]
        }

    }
    
    public dataToRender(apiData: any) {
        return {
            "programAdminId": apiData.programAdminId || 0,
            "isFinalSubmit": apiData.isFinalSubmit,
            "programAdmin": {
                "firstName":'',
                "lastName":'',
                "email": '',
                "phoneNumber": '',
                "personalInvitationNote":''
            },
            "programs": [
                {
                    "id": apiData.programId,
                    "name": apiData.programName || '',
                    "description": '',
                    "startDate": apiData.startDate || '',
                    "endDate": apiData.endDate || '',
                    "numberOfScholars": apiData.noOfScholar || ''
                }
            ]
        }

    }

    private prepareProgramValues(dataObject: any): any {
        let itemToReturn: any = [];
        let baseItemData: any = this.account_data[APP_CONST.PROGRAMS];
        for (let i = 0; i < baseItemData.length; i++) {
            let itemToPush: any = {}
            for (let strKey in baseItemData[i]) {
                const checkObj = (typeof dataObject == 'object' && dataObject) ? ((dataObject.hasOwnProperty(i) && dataObject[i].hasOwnProperty(strKey) && dataObject[i][strKey]) || '') : '';
                const val = Array.isArray(dataObject) && dataObject.length >= i && dataObject[i] ? ((!dataObject[i][strKey] && (dataObject[i][strKey] !== 0)) ? '' : dataObject[i][strKey]) : checkObj;
                itemToPush[strKey] = { value: val, required: (this.notRequired.indexOf(strKey) < 0) }
            }
            itemToReturn.push(itemToPush)
        }
        return itemToReturn;
    }



    private prepareProgramAdminData(dataObject: any): any {
        let baseItemData: any = this.account_data[APP_CONST.PROGRAM_ADMIN];
        let itemToReturn: any = {};
        for (let strKey in baseItemData) {
            if (dataObject[strKey]) {
                if (strKey === APP_CONST.PHONE_NUMBER) {
                    let strtext: string = dataObject[strKey];
                    const zip = strtext && strtext.toString().substring(0, 3);
                    const middle = strtext && strtext.toString().substring(3, 6);
                    const last = strtext && strtext.toString().substring(6, 10); 
                    itemToReturn[strKey] = { value: `(${zip}) ${middle}-${last}`, required: (this.notRequired.indexOf(strKey) < 0) }
                    if (itemToReturn[strKey].value === '(0) -') {
                        itemToReturn[strKey].value = ''
                    }
                } else {
                    itemToReturn[strKey] = { value: (dataObject[strKey] === undefined || dataObject[strKey] === null ? '' : dataObject[strKey]), required: (this.notRequired.indexOf(strKey) < 0) }
                }
            } else {

                itemToReturn[strKey] = baseItemData[strKey];
            }
        }
        return itemToReturn;
    }
    private fillFormWithAPIData(dataObject: any): any {
        /* istanbul ignore else */
        if(dataObject){
            this.isEdited = true;    
            for (let strKey in dataObject) {
                if (this.itemToIgnore.indexOf(strKey) == -1) {
                    if (strKey === APP_CONST.PROGRAMS) {
                        this.account_data[strKey] = this.prepareProgramValues(dataObject[strKey]);
                    } else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                        this.account_data[strKey] = this.prepareProgramAdminData(dataObject[strKey]);
    
                    }
                    else {
                        this.account_data[strKey] = { value: dataObject[strKey], required: (this.notRequired.indexOf(strKey) < 0) }
    
                    }
                } else {
                    this.account_data[strKey] = dataObject[strKey];
                }
            }
        }
    }

    private fillDataForEdit(dataObject: any): any {
        /* istanbul ignore else */
        if (dataObject) { 
            this.isEdited = false;
            for (let strKey in dataObject) {
                if (this.itemToIgnore.indexOf(strKey) == -1) {
                    if (strKey === APP_CONST.PROGRAMS) {
                        this.account_data[strKey] = this.prepareProgramValues(dataObject[strKey]);
                    } else if (strKey === APP_CONST.PROGRAM_ADMIN) {
                        this.account_data[strKey] = this.prepareProgramAdminData(dataObject[strKey]);
    
                    }
                    else {
                        this.account_data[strKey] = { value: dataObject[strKey], required: (this.notRequired.indexOf(strKey) < 0) }
    
                    }
                } else {
                    this.account_data[strKey] = dataObject[strKey];
                }
            }
        }
    }


   

    public onKeyUP($event: any) {
        this.isEdited = true;
        this.isDataCanAutoSave = true;
        if ($event.target.value) {
            this.buttonState = true;
        } else {
            this.buttonState = false;
        }
        
        if($event.target.id === "ele_num_of_scholars"){
            if($event.target.value > 0){
                this.numberOfScholarError = false;
            }else{
                this.numberOfScholarError = true;
            }
        }

    }

    onlyNumber($event: any) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        /* istanbul ignore else */
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {             $event.preventDefault();
        }
    }

    onPaste($event: any, index: number) {
        let value: any = $event.clipboardData.getData('text').replace(/[^ -~\s]/gi, '');
        let originalVal: any = value.replace(/[^0-9]/g, "");
        setTimeout(() => {
            if (value) {
                (<any>this.accountData).programs[index].numberOfScholars.value = originalVal;
            }
        }, 0);
    }




    public emailValidator(mail: string): boolean {
        if (/^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        return false;
    }

    backToProgramList() {
        this.$router.push('/account/programs');
    }

    closeModal(e: { goToHome: boolean, modal: string } = { goToHome: false, modal: '' }) {
        let model = UIkit.modal(`#${e.modal}`);
        if (model) {
            model.hide().then(() => {
                if (e.goToHome) {
                    model.$destroy(true);
                    this.$router.push('/home');
                }
            });
        }
    }

    getPhoneNumber(value: string) {
        let values = APP_UTILITIES.removeSpecialCharacters(value)
        return values
    }

    checkButtonState(account_data: any) {
        for (let key in account_data) {
            if (typeof account_data[key] == 'object' && account_data[key].hasOwnProperty('value') && (this.ignoreItems.indexOf(key) == -1)) {
                const value = account_data[key].value;
                /* istanbul ignore else */
                if (value) {
                    this.buttonState = true;
                    break;
                }
            } else if (typeof account_data[key] == 'object' && !account_data[key].hasOwnProperty('value')) {
                this.checkButtonState(account_data[key]);
            } else if (Array.isArray(account_data[key])) {
                this.checkButtonState(account_data[key]);
            }
        }
        return this.buttonState;
    }

    public removePop() {
        let popupPopDisplay = <HTMLInputElement>document.getElementById('invite-manager-sent');
        /* istanbul ignore else */
        if (popupPopDisplay) {
            popupPopDisplay.style.display = 'none';
            setTimeout(() => {
                let popupNewPopDisplay = <HTMLInputElement>document.getElementById('invite-manager-sent');
                if (popupNewPopDisplay && (popupNewPopDisplay.style.display !== 'none')) {
                    popupNewPopDisplay.style.display = 'none';
                }
                let popupDisplay = <any>document.getElementsByTagName('html');
                popupDisplay[0].classList.remove('uk-modal-page')
            }, 1000)
        }
        this.$router.push('/home');
    }
}